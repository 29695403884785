.one-page {
  width: 100vw;
  height: 100vh;
  page-break-after: always;
}
.signup-blanket {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.5);
}

.signup-modal-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 300000000000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  color:#020202;
  background-color:#fefefe;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


hr {
  display: block;
  position: relative;
  padding: 0;
  margin: 8px auto;
  height: 0;
  width: 100%;
  max-height: 0;
  font-size: 1px;
  line-height: 0;
  clear: both;
  border: none;
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #ffffff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.signup-modal-bubble {
  color:#323232;
  background-color: #fafafa;
  box-shadow:var(--shadow-3);
  border: 1px solid #bbb;
  position: relative;
  border-radius: 1px;
  max-width:100%;
  animation: fade-in 0.5s;
}

.signup-modal-inner-bubble {
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 2em;
  padding-bottom: 2em;
  
}

.signup-column {
  display:flex;
  flex-direction:column;
}

.signup-input {
  border-radius: 1px;
  border:1px solid;
  margin-top: 3pt;
  padding: 18pt;
  font-size: 12pt;
  background-color: var(--bg-color);
  outline: none;
}

.or-container {
  display: flex;
  align-items: center;
  margin-top: 12pt;
  margin-bottom: 12pt;
}

.or-class {
  margin-left: 10px;
  margin-right: 10px;
  color: #666;
  font-size: 12pt;
}

.signup-submit {
  width: 100%;
  
  padding: 12pt;
  border-radius: 2px;
  font-size: 14pt;
  font-weight: 500;
  letter-spacing: 1pt;
  font-family: "Roboto", sans-serif;
  border-width: 0;
  margin-top: 12pt;
}

.subtle-signup-submit {
  background-color: #ff6d8d;
  padding: 6pt;
  color: white;
  border-radius: 4px;
  font-size: 10pt;
  font-weight: 700;
  letter-spacing: 1pt;
  font-family: "Roboto", sans-serif;
  border-width: 0;
  margin-top: 18pt;
}

.signup-title {
  text-align: center;
  color: black;
  font-size: 18pt;
  margin-bottom: 1em;
}

.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
}

.google-container {
  display: flex;
  justify-content: center;
}

.forgotpassword-container {
  background-color: #eee;
  cursor: pointer;
  padding-top: 15pt;
  padding-bottom: 15pt;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-close-button {
  cursor: pointer;
  color: #777;
  font-size: 14pt;
  position: absolute;
  right: 5pt;
  top: 5pt;
}
.subtle-signup-submit-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.squareButton {
    transition: box-shadow .1s,transform .17s,-webkit-box-shadow .1s,-webkit-transform .17s;
    padding: 10px;
    color:#323232;
     background-color: #fff;
     border-radius: 30px;
     
  cursor: pointer;
 box-shadow: var(--shadow-3);
   border: 0.75px solid #bbb;

        font-family: 'Open Sans', sans-serif;

}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}